import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of deload week`}</em></p>
    <p>{`DB Deadlifts 12-12-12`}</p>
    <p>{`Single Leg Box Jumps 6-6-6/leg`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20 Calorie Row`}</p>
    <p>{`20 Burpee DB Deadlifts (50/35s)`}</p>
    <p>{`100ft Farmers Walk`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday, June 3rd we will have a Mobility for Athletic Performance
workshop from 1:45-3:00pm.  This workshop will be taught by Stephanie
Bellissimo and will focus on floor work and locomotion for CrossFit
athletes.  This intro class is free to all CrossFit the Ville members
and just \\$15 for non members.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      